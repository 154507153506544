/* eslint-disable */
// @ts-ignore

function calculatePrice(spreadsheetData: any, priceFormData: any, priceData: any): void {
}

function getVersicherungssummeAktuell(biogasanlageItems) {
  let summ = 0;
  biogasanlageItems.forEach((biogasanlageItem, index: number) => {
    biogasanlageItem.biogasanlageItemAnzahlDerMotorenJeAnlageItems.forEach((motorItem) => {
      summ += Number(motorItem.VersicherungssummeMotor);
    });
    summ += Number(biogasanlageItem.VersicherungssummeAnlageOhneMotor);
    summ += Number(biogasanlageItem.VersicherungssummeFermenterbiologie);
    biogasanlageItem.MaschineAbweichendVomBiogasanlagentarif.items.forEach((item) => {
      summ += Number(item.Versicherungssumme);
    });
  });
  return summ.toFixed(2);
}

function fillErgebnisseTarifkalkulationPramie(priceResponseData, spreadsheetData, ergebnisseTarifkalkulation) {

  const Deckungserweiterungen = spreadsheetData.Deckungserweiterungen;
  const biogasanlageItems = spreadsheetData.biogasanlageItems;


  const MaschineAbweichendSumm = getMaschineAbweichendVomBiogastarifItemsSumm(biogasanlageItems);

  if (checkIfPriceDataHaveResponse(priceResponseData, 'tarif')) {
    const PositionsdatenSumm = getPramieBiogasanlageSumm(priceResponseData.tarif.priceResponse.Result.Maschinen, biogasanlageItems, Deckungserweiterungen);
    ergebnisseTarifkalkulation.tarif.tarifPramie = PositionsdatenSumm + MaschineAbweichendSumm;
  }
  if (checkIfPriceDataHaveResponse(priceResponseData, 'variant1')) {
    const PositionsdatenSumm1 = getPramieBiogasanlageSumm(priceResponseData.variant1.priceResponse.Result.Maschinen, biogasanlageItems, Deckungserweiterungen);
    ergebnisseTarifkalkulation.SBVariante1.tarifPramie = PositionsdatenSumm1 + MaschineAbweichendSumm;
  }
  if (checkIfPriceDataHaveResponse(priceResponseData, 'variant2')) {
    const PositionsdatenSumm2 = getPramieBiogasanlageSumm(priceResponseData.variant2.priceResponse.Result.Maschinen, biogasanlageItems, Deckungserweiterungen);
    ergebnisseTarifkalkulation.SBVariante2.tarifPramie = PositionsdatenSumm2 + MaschineAbweichendSumm;
  }
  if (checkIfPriceDataHaveResponse(priceResponseData, 'variant3')) {
    const PositionsdatenSumm3 = getPramieBiogasanlageSumm(priceResponseData.variant3.priceResponse.Result.Maschinen, biogasanlageItems, Deckungserweiterungen);
    ergebnisseTarifkalkulation.SBVariante3.tarifPramie = PositionsdatenSumm3 + MaschineAbweichendSumm;
  }
}

function getPramieBiogasanlageSumm(Maschinen, biogasanlageItems, deckungserweiterungen) {
  const RkBiogasanlageItemsWithDiscounts = getRkBiogasanlageItemsWithDiscounts(biogasanlageItems);

  let summ = 0;
  RkBiogasanlageItemsWithDiscounts.forEach((item, key) => {
    let result = Number(Maschinen[key]['Prämie_SB_Var1_Maschine']) * (1 + Number(item.ZuschlagRegelenergieFlexbetriebMotor) / 100);
    result *= (1 - Number(item.NachlassFurKaskodeckungAnlage) / 100);
    result *= (1 - Number(item.NachlassFurKaskodeckungBHKW) / 100);
    result *= (1 + Number(item.ZuschlagRegelenergieFlexbetriebGesamteAnlage) / 100);
    result *= Number(item.basisdeckungDiscountFactor);

    if(deckungserweiterungen.items[0].variante1 === 'Ja') {
      result *= (1 + Number(Maschinen[key]['Prämienfaktor']) / 1000);
    }
    summ += result;
  });
  return summ;
}

function getMaschineAbweichendVomBiogastarifItemsSumm(biogasanlageItems) {

  let summ = 0;

  biogasanlageItems.forEach((biogasanlageItem, index: number) => {
    biogasanlageItem.MaschineAbweichendVomBiogasanlagentarif.items.forEach((item) => {
      if (checkingMaschineAbweichendVomBiogastarifItemFilled(item)) {
        summ += Number(item.NettobeitragVorRabattenUndZuschlagen);
      }
    });
  });

  return summ;
}
function checkingMaschineAbweichendVomBiogastarifItemFilled(item: any) {
  if ((item.MKZ === '' || item.MKZ === null || item.MKZ === '0') && (item.Bewertungsjahr === '' || item.Bewertungsjahr === null || item.Bewertungsjahr === '0') && (item.Versicherungssumme === 0 || item.Versicherungssumme === null)) {
    return false;
  }
  return true;
}

function getRkBiogasanlageItemsWithDiscounts(biogasanlageItems: any): any[] {
  let result: any[] = [];

  biogasanlageItems.forEach((item, index: number) => {
    const basisdeckungDiscountFactor = getBasisdeckungDiscountFactor(item.ProduktvarianteDerAnlage);

    item.biogasanlageItemAnzahlDerMotorenJeAnlageItems.forEach((motorItem) => {
      result.push({
        type: 'AnzahlDerMotoren',
        ZuschlagRegelenergieFlexbetriebMotor: motorItem.ZuschlagRegelenergieFlexbetriebMotor,
        NachlassFurKaskodeckungAnlage: 0,
        NachlassFurKaskodeckungBHKW: motorItem.NachlassFurKaskodeckungBHKW,
        ZuschlagRegelenergieFlexbetriebGesamteAnlage: item.Deckungserweiterungen.ZuschlagRegelenergieFlexbetriebGesamteAnlage,
        basisdeckungDiscountFactor: basisdeckungDiscountFactor,
      });
    });
    if (item.VersicherungssummeAnlageOhneMotor > 0) {
      result.push({
        type: 'AnlageOhneMotor',
        ZuschlagRegelenergieFlexbetriebMotor: 0,
        NachlassFurKaskodeckungAnlage: item.NachlassFurKaskodeckungAnlage,
        NachlassFurKaskodeckungBHKW: 0,
        ZuschlagRegelenergieFlexbetriebGesamteAnlage: item.Deckungserweiterungen.ZuschlagRegelenergieFlexbetriebGesamteAnlage,
        basisdeckungDiscountFactor: basisdeckungDiscountFactor,
      });
    }
    if (item.ProduktvarianteDerAnlage === 'Kompaktdeckung' || item.ProduktvarianteDerAnlage === 'Optimaldeckung') {
      result.push({
        type: 'Fermenterbiologie',
        ZuschlagRegelenergieFlexbetriebMotor: 0,
        NachlassFurKaskodeckungAnlage: 0,
        NachlassFurKaskodeckungBHKW: 0,
        ZuschlagRegelenergieFlexbetriebGesamteAnlage: item.Deckungserweiterungen.ZuschlagRegelenergieFlexbetriebGesamteAnlage,
        basisdeckungDiscountFactor: 1,
      });
    }
  });
  return result;
}
function checkIfPriceDataHaveResponse(priceData, key: string): boolean {
  if (typeof priceData[key].priceResponse !== 'object' || Object.keys(priceData[key].priceResponse).length === 0) {
    return false;
  }
  return true;
}
function getBasisdeckungDiscountFactor(produktvarianteDerAnlage): number {
  if (produktvarianteDerAnlage === 'Basisdeckung') {
    return 0.8;
  }
  return 1;
}

export {
  calculatePrice, fillErgebnisseTarifkalkulationPramie, getVersicherungssummeAktuell
};
